$breakpointMap: (
  xs: 550px /* todo: fixit (weird, ugly) */,
  sm: 768px,
  md: 1024px,
  lg: 1110px /* todo: fixit (weird, ugly) */,
  xl: 1536px,
);

@mixin _mediaQuery($name, $operator) {
  @if not map-has-key($breakpointMap, $name) {
    @warn "Warning: `#{$name}` is not a valid breakpoint name.";
  } @else {
    $breakpoint-value: map-get($breakpointMap, $name);

    @if $operator == min {
      @media (min-width: #{$breakpoint-value + 1}) {
        @content;
      }
    } @else {
      @media (max-width: $breakpoint-value) {
        @content;
      }
    }
  }
}

@mixin minMediaWidth($name) {
  @include _mediaQuery($name, min) {
    @content;
  }
}

@mixin maxMediaWidth($name) {
  @include _mediaQuery($name, max) {
    @content;
  }
}
