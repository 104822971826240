@use 'src/css/breakpoint.scss' as *;

.indexSection {
  margin: 80px auto;

  @include maxMediaWidth(lg) {
    margin: 48px auto;
  }

  @include maxMediaWidth(sm) {
    margin: 36px auto;
  }
}

.title {
  padding-bottom: 24px;

  @include maxMediaWidth(lg) {
    font: var(--font-h5);
  }

  @include maxMediaWidth(sm) {
    font: var(--font-h6);
  }
}

.wrapList {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.link {
  padding: 6px 16px;
  font: var(--font-h12);
  color: var(--color-foreground);
  background-color: var(--color-primary-dark);
  border-radius: 8px;

  &:hover {
    text-decoration: none;
    color: var(--color-foreground);
    background-color: var(--color-primary-dimmed);
  }

  @include maxMediaWidth(sm) {
    font: var(--font-h13);
  }
}
