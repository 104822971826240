@use 'src/css/breakpoint.scss' as *;

.primary-color {
  color: var(--main-title-color);
}

.secondary-color {
  color: var(--header-secondary-text-dark-color);
}

.special-color {
  color: var(--today-statistics-value-dark-color);
}

.h1 {
  font: var(--font-h1);

  @include maxMediaWidth(lg) {
    font: var(--font-h1-md);
  }

  @include maxMediaWidth(sm) {
    font: var(--font-h1-sm);
  }
}

.h2 {
  font: var(--font-h2);

  @include maxMediaWidth(lg) {
    font: var(--font-h2-md);
  }

  @include maxMediaWidth(sm) {
    font: var(--font-h2-sm);
  }
}

.h3 {
  font: var(--font-h3);

  @include maxMediaWidth(lg) {
    font: var(--font-h3-md);
  }

  @include maxMediaWidth(sm) {
    font: var(--font-h3-sm);
  }
}

.h4 {
  font: var(--font-h4);

  @include maxMediaWidth(lg) {
    font: var(--font-h4-md);
  }

  @include maxMediaWidth(sm) {
    font: var(--font-h4-sm);
  }
}

.h5 {
  font: var(--font-h5);

  @include maxMediaWidth(lg) {
    font: var(--font-h5-md);
  }

  @include maxMediaWidth(sm) {
    font: var(--font-h5-sm);
  }
}

.h6 {
  font: var(--font-h6);

  @include maxMediaWidth(lg) {
    font: var(--font-h6-md);
  }

  @include maxMediaWidth(sm) {
    font: var(--font-h6-sm);
  }
}

.center {
  text-align: center;
}
